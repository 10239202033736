import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import OmegaCategoryBtns from '../../components/preowned/OmegaCategoryBtns'

// markup
const OmegaSpeedmaster = () => {
  const data = useStaticQuery(
    graphql`
      query queryOmegaSpeedmaster {
        products: allStrapiProduct(
          filter: { brand: { eq: "Omega" }, model: { eq: "Speedmaster" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Omega Speedmaster Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/omega/omega-speedmaster/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Omega Speedmaster Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/omega/omega-speedmaster/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-omega-speedmaster-header.png"
                alt="Pre-Owned Certified Used Omega Speedmaster Watches Header"
                aria-label="Used Omega Speedmaster Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Omega Speedmaster</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE OMEGA SPEEDMASTER WATCHES AT
              GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED OMEGA SPEEDMASTER WATCHES WITH
              GRAY AND SONS JEWELERS
            </h3>
            <p>
              Many people online are looking for a reputable place to ‘sell my Omega Speedmaster
              watch’ online or in person. Gray and Sons Jewelers will buy your Omega Speedmaster
              watch right now for cash in person or online at{' '}
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com.</a> For over 42 years,
              Gray and Sons Jewelers has been in the business of buying, selling, trading, and
              repairing genuine Omega watches.
              <br />
              <br />
              In our store, we carry six Master-trained watchmakers with over 150 years of combined
              experience in the overhauling and detailing of these timepieces. Aside from the Omega
              Speedmaster, we also work with other Omega watch model collections such as the Omega
              Seamaster, Omega Constellation, Omega Seamaster Diver, Omega De Ville, and many more!
              Visit our website and <a href="/chat-with-rich">speak to a live representative </a>
              about Omega Speedmasters today! Senior Sales Associates, Viktoria and Rich, are
              available through our Live Chat for any and all questions regarding Omega Speedmaster
              watches.
              <br />
              <br />
              <b>About Pre-Owned Omega Speedmaster Watches at Gray and Sons</b>
              <br />
              <br />
              Making history as the first watch ever worn in space and to ever walk the moon, the
              Omega Speedmaster is a collection that impacted the luxury watch market tremendously.
              Introduced in 1957, the iconic Omega Speedmaster reference 2915 was one of the first
              watches to carry a tachymeter within the bezel itself. Since Ref. 2915, Omega has been
              releasing several looks and models of the Speedmaster Collection which displayed the
              chronograph displayed in styles inspired by carrying muses. These sub-collections not
              only bring a different aesthetic to the collection but the different iterations are
              nicknamed based on what they did for the watch society. Some of the most popular Omega
              Speedmaster nicknamed models are: Omega Speedmaster ‘First Omega In Space’, Omega
              Speedmaster ‘Snoopy’, Omega Speedmaster ‘Broad Arrow’, Omega Speedmaster ‘Ed White’,
              Omega Speedmaster ‘Ultraman’. There's a reason Omega is one of the top-name watch
              brands around the world. Holding the historic title of the first watch worn in space,
              Omega prides itself on being the world standard for its pristine watchmaking skills.
              <br />
              <br />
              <b>Buying Pre-Owned Omega Speedmaster Watches</b>
              <br />
              <br />
              There is plenty of variety within second-hand Omega Speedmaster watches, including
              various different sizes, styles, and complications. From minimal solid colored models
              to vibrant variants featuring colorful dials and co-axial movements; if you’re in the
              market to buy a certified pre-owned Omega Speedmaster watch, then look no further than
              Gray &amp; Sons. Make a deal today with one of our decision-makers today to purchase
              the pre owned Omega Speedmaster watch of your dreams.On the other hand, if you’ve
              recently thought to yourself, I want to sell my Omega Speedmaster watch for cash,
              reach out to Gray &amp; Sons or visit <a href="/chat-with-rich">CHAT WITH RICH </a> to
              get the best cash offer available in the market. Popular Pre-Owned Omega Speedmaster
              Reference Numbers are: Ref. 2998, Ref. 145.012, Ref. 145.022, Ref.
              311.30.42.30.01.005, Ref. 311.92.44.51.01.003, Ref. 311.63.44.51.06.001, Ref.
              311.98.44.51.51.001, and Ref. 3578.51.00.
              <br />
              <br />
              <b> Servicing Pre-Owned Omega Speedmaster Watches</b>
              <br />
              <br />
              Gray &amp; Sons boasts a full-service Omega Speedmaster watch repair shop on-site with
              a team of highly-trained skilled watchmakers. Due to their detailed workmanship and
              Swiss-made movements, the servicing and repair of second-hand Omega Speedmaster
              watches should only be done by expert watchmakers. Whether for a complicated repair
              job, regular maintenance, or battery replacement, our customers agree that Gray &amp;
              Son’s independent Omega watch repair center is the best in Miami. We are a real watch
              and jewelry store located in Surfside/ Miami across from Bal Harbour Shops, with real
              watch and jewelry experts on-site, open six days a week, not a virtual shop. Our
              customers say we are #1 Miami used watch buyer and seller proudly assisting for the
              past 42 years sellers and buyers of pre-owned Omega Speedmaster watches in Miami,
              Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny
              Isles, Aventura, Brickle, Pinecrest, Fort Lauderdale, and many more areas. Find us
              online at{' '}
              <a href="/">
                www.grayandsons.com. We offer certified pre-owned watches.
              </a>
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/omega/">
                <button>SHOP OMEGA WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK OMEGA WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <OmegaCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default OmegaSpeedmaster
